  <template>
    <div class="">
       Copyright 2021. All Rights Reserved By Spotlawapp
    </div>
</template>
<script>
export default {
  name: 'Footer',

}
</script>
