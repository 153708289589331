  <template>
<div>

<section class="top-header">
  <div class="container ">
    <div class="columns">
      <div class="column">
        <a href="" class="donwload-btn"> Download </a> <span class="top-header-social-icon"> <a href=""> <i class="fa fa-facebook" aria-hidden="true"></i> </a> <a href=""> <i class="fa fa-twitter" aria-hidden="true"></i> </a>  <a href=""> <i class="fa fa-google" aria-hidden="true"></i> </a>  <a href=""> <i class="fa fa-instagram" aria-hidden="true"></i> </a></span>
      </div>
      <div class="column has-text-right">
        <a href="" class="color-white"><i class="fa fa-question-circle" aria-hidden="true"></i> Help </a>  <router-link v-if="!auth" class="navbar-item d-inline-block login-btn"  to="/login">Login</router-link>
            <a  v-if="auth" href="#"  @click="logout()" >Logout</a>
      </div>
    </div>
  </div>
  </section>

<nav class="navbar navigation-menu" role="navigation" aria-label="main navigation">
  <div class="container">
  <div class="navbar-brand">
    <a class="navbar-item px-0 py-3" href="#">
      <img src="https://spotlawapp.com/wp-content/uploads/2020/08/logo-new.png" >
    </a>
    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <div class="navbar-end navigation">
      <router-link class="navbar-item"  to="/">Home</router-link>
      <router-link class="navbar-item"  to="/latestjudgement"> Latest Judgement </router-link>
      <router-link class="navbar-item"  to="/landmark">Landmark</router-link>
      <router-link class="navbar-item"  to="/bookmark">Bookmarked</router-link>
      <router-link class="navbar-item"  to="/judges">Judges</router-link>
      <!-- <router-link class="navbar-item"  to="/browse">Browse</router-link> -->
       <b-navbar-dropdown label="Browse" >
               <router-link class="navbar-item"  to="/statues">Statues</router-link>
                <router-link class="navbar-item"  to="/subjects">Subjects</router-link>
            </b-navbar-dropdown>

            <b-navbar-dropdown label="Search" >
               <router-link class="navbar-item"  to="/appellant">Appellant</router-link>
                <router-link class="navbar-item"  to="/respondent">Respondent</router-link>
                <router-link class="navbar-item"  to="/citation">Citation</router-link>
                <router-link class="navbar-item"  to="/word">Word</router-link>
                <router-link class="navbar-item"  to="/date">Date</router-link>
            </b-navbar-dropdown>
<!-- <router-link class="navbar-item"  to="/search">Search</router-link> -->
    </div>



    <!-- <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
            <router-link v-if="!auth" class="navbar-item"  to="/login">Login</router-link>
            <a  v-if="auth" href="#"  @click="logout()" >Logout</a>
        </div>
      </div>
    </div> -->
  </div>
  </div>
</nav>

</div>
</template>
<script>
import "../assets/style/custom.css"
export default {
  name: 'Header',

  computed:{
      auth(){
        return this.$store.getters.isAuthenticated
      }

  },
  methods:{
      logout(){
         this.$store.dispatch('logout');
      }
  }

}
</script>


