import Vue from "vue";
import Router from 'vue-router'
import store from '../store'
Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  next()
  return

}


export default new Router({
  mode: 'history',
  routes: [
    {
        name:"",
        path: "/",
        component: () => import("@/views/LatestJudgement"),
        beforeEnter: ifAuthenticated,
    },
    {
      path: "*",
      component: () => import("@/views/Dashboard"),
      beforeEnter: ifAuthenticated,
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/Login"),
      beforeEnter: ifNotAuthenticated,
    },
    {
      name:'LatestJudgement',
      path:'/latestjudgement',
      component: () => import("@/views/LatestJudgement"),
      beforeEnter: ifAuthenticated,
    },
    {
      name:'LatestJudgement.Details',
      path:'/latestjudgement/:slugid',
      component: () => import("@/views/LatestJudgement/LatestJudgementDetails"),
      props: true
    } ,
    {
      name:'Landmark',
      path:'/landmark',
      component: () => import("@/views/LandMark/Index"),
      beforeEnter: ifAuthenticated,
    },
    {
      name:'Judges',
      path:'/judges',
      component: () => import("@/views/Judges/Index"),
      beforeEnter: ifAuthenticated,

    },
    {
      name:'Statues',
      path:'/statues',
      component: () => import("@/views/Browse/Statues"),
      beforeEnter: ifAuthenticated,

    },
    {
      name:'Subjects',
      path:'/subjects',
      component: () => import("@/views/Browse/Subjects"),
      beforeEnter: ifAuthenticated,
    },
    {
      name:'Appellant',
      path:'/appellant',
      component: () => import("@/views/Search/Appellant"),
      beforeEnter: ifAuthenticated,
    },
    {
      name:'Respondent',
      path:'/respondent',
      component: () => import("@/views/Search/Respondent"),
      beforeEnter: ifAuthenticated,
    },
    {
      name:'Citation',
      path:'/citation',
      component: () => import("@/views/Search/Citation"),
      beforeEnter: ifAuthenticated,
    },
    {
      name:'Word',
      path:'/word',
      component: () => import("@/views/Search/Word"),
      beforeEnter: ifAuthenticated,
    },
    {
      name:'Date',
      path:'/date',
      component: () => import("@/views/Search/Date"),
      beforeEnter: ifAuthenticated,
    },
    /* {
      name:'Bookmark',
      path:'/bookmark',
      component: () => import("@/views/Bookmark")
    }, */
  ]


///




})