import Vue from 'vue'
import Vuex from 'vuex'
const axios = require('axios');
import router from '../router'
Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    authenticated :false,
    userID:null,
    idToken: localStorage.getItem('token') || '',
  },
  mutations:{
    setAuthentication (state, status){
      state.authenticated = status
    },
    authUser (state, userData){
      state.idToken = userData.idToken
      state.userID = userData.userID
    },
    clearAuthData(state){
      state.idToken = null;
      state.userID = null;
    }
  },
  actions:{
    tryAutoLogin ({commit}){
      const Token = localStorage.getItem('token');
      const userID = 2;
      if(!Token){
        return;
      }
      const expirationDate = localStorage.getItem('expires_in')
      const now = new Date();
      console.log(now);
      console.log('now');
      console.log(expirationDate);
      if (now > expirationDate) {
        console.log('clear auth data');
        commit('logout');
        commit('clearAuthData');
        console.log('clear auth data');
        localStorage.removeItem('expires_in')
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        router.replace('/login')
        return;
      }
      commit('authUser', {
        idToken: Token,
        userID: userID
      })
  },
    login({commit}, authData){
          var baseUrl = 'http://staging.spotlawapp.com/newapi/signin';
          axios.get(baseUrl, {
            crossDomain: true,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },           
            params: { 
              email: authData.email,
              password:authData.password
            }
          }).then(res => {
            console.log(res);
              const now = new Date()
            const expirationDate = new Date(now.getTime() + 600 * 1000  );
             console.log(res.data.data[0].idUser);
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('userid', res.data.data[0].idUser);
              localStorage.setItem('datenow', now);
              localStorage.setItem('expires_in', expirationDate);
              commit('authUser', {
                idToken: res.data.token,
                userID: res.data.data[0].idUser
              })
              router.push("/");
          })
          .catch(error => {
            localStorage.removeItem('token')
            console.log(error);
          })
  },/// Login Function ends
  logout ({commit}) {
    commit('clearAuthData');
    console.log('clear auth data');
    localStorage.removeItem('expires_in')
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    router.replace('/login')
  },
  },
  getters:{
    isAuthenticated: state => !!state.idToken,
	}
})