  <template>
  <div>
    <footer class="footer  footer-section">
        <div class="container">
        <div class="content has-text-left">
                <div class="columns">
                    <div class="column is-4">
                       <h4 class=" has-text-white"> About Us </h4>
                       <p class="mt-5">
                        We bring law judgements to you, anywhere, anytime! We constantly update our database of judgements and enhance the mobile app to make it easier to find information you need.
                        </p>
                    </div>
                    <div class="column footer-link is-2">
                       <h4 class=" has-text-white">Useful Links</h4>
                        <ul class="mt-5">
                       <li><router-link to="#"> Word Search </router-link></li>
                       <li>  <router-link to="#"> Name of Appellant </router-link> </li>
                       <li>  <router-link to="#">  Name of Respondent </router-link> </li>
                       <li>  <router-link to="#"> Citation </router-link>  </li>
                       </ul>
                    </div>
                    <div class="column footer-link is-3">
                         <h4 class=" has-text-white is-invisible"> Useful </h4>
                    <ul class="mt-5">
                       <li><router-link to="#"> Date of Judgment </router-link></li>
                       <li>  <router-link to="#"> Name of Hon'ble Judges </router-link> </li>
                       <li>  <router-link to="#">  Statutes </router-link> </li>
                       <li>  <router-link to="#"> Subject Searches </router-link>  </li>
                       </ul>

                    </div>
                    <div class="column is-3">
                        <h3 class=" has-text-white">Contact Us</h3>
                        <p>   SpotLaw Apps Pvt.Ltd. D-61, <br/>East of Kailash New Delhi – 110065 INDIA </p>
                        <p> Tel: +91 11 46579779 </p>
                    </div>
                </div>
        </div>

        </div>


    </footer>
    <div class="container has-text-centered py-3">
     <small> <Copyright/> </small>
      </div>
  </div>
</template>
<script>
import Copyright from "@/components/Copyright.vue";
export default {
  components: { Copyright},
  name: 'Footer',

}
</script>
